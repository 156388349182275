<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <p class="imgItem1">ศูนย์ช่วยเหลือ</p>
        <!-- <img :src="importedImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <!-- <h1>Cam kết bảo mật</h1> -->
        <!-- <div class="title-line"></div> -->
        <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import importedImg1 from "@/page/passmorecompany/components/img/4_1.png";

export default {
  data() {
    return {
      altText: "protocolTitle",
      // importedImg1: importedImg1,
      text: `.เพื่อให้เป็นไปตามบทบัญญัติของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลของไทย (PDPA) และกฎหมายและข้อบังคับที่เกี่ยวข้อง นโยบายความเป็นส่วนตัวนี้จะประกาศให้คุณทราบในข้อกำหนดต่อไปนี้:

1.วัตถุประสงค์ที่จำเป็นของการรักษา
  เราต้องการข้อมูลส่วนบุคคลที่กล่าวถึงก่อนหน้านี้เพื่อวัตถุประสงค์ดังต่อไปนี้:
i.ระบุบุคคลที่ร้องขอผลิตภัณฑ์ใด ๆ ที่จัดให้;
ii.มีองค์ประกอบที่จำเป็นในการดำเนินการวิเคราะห์เพื่อพิจารณาว่าผู้สมัครมีความสามารถในการจัดหาผลิตภัณฑ์ที่ร้องขอหรือไม่
iii.เตรียมความเห็นของบุคคลเกี่ยวกับการร้องขอผลิตภัณฑ์
iv.สร้างและลงทะเบียนผู้ใช้ในแอปพลิเคชันคอมพิวเตอร์
v.พัฒนาสัญญาสำหรับผลิตภัณฑ์ที่จำเป็น
vi.ปฏิบัติตามกฎหมาย ข้อบังคับ และข้อกำหนดทางกฎหมายที่บังคับใช้

2.วัตถุประสงค์รอง
i.ดำเนินกิจกรรมการตลาด การโฆษณา การส่งเสริมการขายและการตลาดทางโทรศัพท์ผ่านสื่อใดๆ และ/หรือวิธีการอิเล็กทรอนิกส์สำหรับผลิตภัณฑ์และ/หรือบริการที่จัดทำโดย

3.กลไกที่เจ้าของสามารถแสดงความคิดเห็นเชิงลบเพื่อวัตถุประสงค์รองได้
หากคุณไม่ต้องการให้ข้อมูลส่วนบุคคลของคุณถูกประมวลผลเพื่อวัตถุประสงค์รอง จากนี้ไป คุณสามารถแจ้งให้เราทราบเรื่องข้างต้นทางอีเมล โดยระบุชื่อและข้อมูลการติดต่อของเจ้าของ และแนบบัตรประจำตัวที่เป็นทางการ การคัดค้านการใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์เหล่านี้อาจไม่ถือเป็นเหตุให้เราปฏิเสธที่จะให้บริการและผลิตภัณฑ์แก่คุณตามที่คุณร้องขอหรือทำสัญญากับเรา

4.การถ่ายโอนข้อมูลส่วนบุคคล
การใช้ข้อมูลส่วนบุคคลของเจ้าของจะถูกควบคุมและจำกัดโดยนโยบายความเป็นส่วนตัวนี้ และข้อมูลดังกล่าวจะถูกเก็บไว้ตามระยะเวลาที่ระบุไว้ในบทบัญญัติทางกฎหมายที่เกี่ยวข้อง
I.เพื่อให้เป็นไปตามวัตถุประสงค์หลักที่ระบุไว้ในส่วนที่ 3 ของนโยบายความเป็นส่วนตัวนี้ คุณสามารถถ่ายโอนข้อมูลของคุณได้โดยไม่ต้องได้รับความยินยอมอย่างชัดแจ้งตามบทบัญญัติของมาตรา 37 ของกฎหมายเพื่อ:
a. สำหรับหน่วยงานที่มีอำนาจควบคุม บริษัทสาขา หรือบริษัทในเครือ...
ข. หน่วยงานตุลาการ รัฐบาล การเงิน หรือการคลัง เพื่อปฏิบัติตามบทบัญญัติทางกฎหมายที่บังคับใช้และเพื่อชี้แจงเรื่องต่าง ๆ หรือใช้สิทธิ์ใด ๆ
ค. บริษัทประกันภัยที่ออกแบบมาเพื่อถือกรมธรรม์การว่างงาน ความทุพพลภาพ หรือการเสียชีวิต ข้อมูลนี้จะถูกแชร์เมื่อมีการสรุปประกันเท่านั้น
d. บริษัทข้อมูลเครดิต
II.เจ้าของอนุญาตให้ถ่ายโอนข้อมูลส่วนบุคคลไปยังผู้ให้บริการบุคคลที่สามในระดับชาติและต่างประเทศที่สนับสนุนเราด้วยกระบวนการใด ๆ โดยมีวัตถุประสงค์ที่เข้มงวดในการยืนยันตัวตนของพวกเขาตลอดจนการประเมินความเสี่ยงและประวัติเครดิตโดยตรงหรือโดยอ้อมในระหว่างกระบวนการของ การทำสัญญาและหลังจากนั้นเพื่อการปรับปรุงวัตถุประสงค์
III.หน่วยงานเรียกเก็บเงิน: ในการใช้สิทธิ์ที่สร้างขึ้นอันเป็นผลมาจากสัญญาเงินกู้ระหว่างเจ้าของและเจ้าของ ข้อมูลส่วนบุคคลของฝ่ายหลังจะถูกโอนไปยังหน่วยงานเรียกเก็บเงินซึ่งตนได้ทำข้อตกลงสำหรับการจัดการบัญชี ในทุกกรณี ข้อมูลส่วนบุคคลที่ถ่ายโอนจะเป็นข้อมูลที่จำเป็นอย่างยิ่งต่อการใช้สิทธิในการเก็บรวบรวมตามสัญญา โดยเฉพาะอย่างยิ่งจะรวมข้อมูลการติดต่อของผู้ยืมตลอดจนจำนวนเงินใด ๆ ที่ค้างชำระอันเป็นผลมาจากข้อตกลงเงินกู้ระหว่างและเจ้าของ
IV.ในกรณีของข้อมูลไบโอเมตริกซ์ที่เก็บรวบรวม การถ่ายโอนข้อมูลดังกล่าวไปยังบุคคลที่สามหรือผู้ที่รับผิดชอบจะดำเนินการในกรณีที่มีการระบุตัวตนในการทำสัญญาผลิตภัณฑ์ การดำเนินการ และการปกป้องและการจัดการสิ่งเดียวกันโดยซัพพลายเออร์
ในกรณีที่มีการถ่ายโอนไปยังบุคคลที่สามในระดับชาติหรือต่างประเทศ จะรับประกันว่าผู้รับจะเคารพข้อมูลที่ให้ไว้อย่างชัดแจ้งเพื่อวัตถุประสงค์ในการประมวลผลที่ระบุไว้ บุคคลที่สามเหล่านี้อาจมีส่วนร่วมในการดำเนินกิจกรรมที่มีลักษณะคล้ายคลึงกันหรืออาจเชื่อมโยงกันด้วยความสัมพันธ์ตามสัญญา ข้อมูลข้างต้นรวมถึงแต่ไม่จำกัดเฉพาะหน่วยงานและหน่วยงานของรัฐ บริษัทเรียกเก็บเงิน หน่วยงานทางการเงิน (ระดับชาติหรือต่างประเทศ) สถาบันประกันภัยและ/หรือพันธบัตร และบุคคลและนิติบุคคลที่ให้บริการแก่ การโอนเหล่านี้จะมีวัตถุประสงค์:
ถึง. ปฏิบัติตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลที่ถือโดยภาคเอกชน
ข. ต้นกำเนิดที่ถูกต้อง การบำรุงรักษา และ/หรือการปฏิบัติตามภาระผูกพันและสิทธิ์ที่ทำสัญญาผ่านสัญญาที่ทำขึ้นระหว่างคู่สัญญา
ค. การรับประกันและการคุ้มครองสิทธิในการชำระเงิน
ง. รับประกันสิทธิตามสัญญาทุกประเภทที่เป็นของ;
และ. การคุ้มครองสิทธิของเจ้าของ
ในกรณีของวัตถุประสงค์ที่อธิบายไว้ในย่อหน้าสุดท้ายนี้ เราจะติดต่อเจ้าของเพื่อให้พวกเขามีความเป็นไปได้ที่จะแสดงการปฏิเสธเกี่ยวกับการใช้และการประมวลผลข้อมูลส่วนบุคคลของพวกเขา
นอกเหนือจากกรณีที่จำกัดก่อนหน้านี้ เจ้าของจะต้องให้ความยินยอมอย่างชัดแจ้งในการถ่ายโอนข้อมูลส่วนบุคคลของเขา

5.ขั้นตอนและกลไกในการทราบการเปลี่ยนแปลงในนโยบายความเป็นส่วนตัว
นโยบายความเป็นส่วนตัวนี้อาจได้รับการแก้ไข เปลี่ยนแปลง หรืออัปเดตเนื่องจากข้อกำหนดทางกฎหมายใหม่ ความต้องการผลิตภัณฑ์หรือบริการที่เรานำเสนอ หลักปฏิบัติด้านความเป็นส่วนตัวของเรา การเปลี่ยนแปลงในรูปแบบธุรกิจของเรา หรือด้วยเหตุผลอื่น ๆ ภายใต้เงื่อนไขที่กล่าวมาข้างต้น เจ้าของจะต้องเยี่ยมชมไซต์เป็นระยะเพื่อตรวจสอบการเปลี่ยนแปลงประกาศนี้

6.ความยินยอม
โดยการลงทะเบียนในแอปพลิเคชันมือถือของเรา เจ้าของยินยอมให้ข้อมูลส่วนบุคคลของตนได้รับการประมวลผลตามข้อกำหนดของนโยบายความเป็นส่วนตัวนี้

7.ติดต่อ
หากคุณมีคำถามใดๆ เกี่ยวกับเนื้อหา การตีความ หรือขอบเขตของนโยบายความเป็นส่วนตัวนี้ โปรดส่งอีเมลถึงเรา {`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: #ffffff;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}

.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.imgItem2 {
  max-width: 100%;
}

.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

h1 {
  margin-top: 50px;
  margin-left: 490px;
  color: #3f4842;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}

.textBox {
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}

.imgBox2 {
  transform: scale(90%);
  /* transform: scale(80%); */
  margin: -50px auto 100px;
  height: 1800px;
  /* box-shadow: 0 1px 4.8px 0px rgba(0, 0, 0, 0.25); */
  /* border: 2px solid #4D64E4; */
  border-radius: 36px;
  border-top: 1px solid #457b9d;
  border-bottom: 1px solid #457b9d;
}
.textBox {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}
/* .imgBox2{
  border-radius: 0;
  border-top: 3px solid #7c2af8;
  border-left: 2px solid #e9e9e9;
  border-right: 2px solid #e9e9e9;
  border-bottom: 3px solid #c1c1c1;
} */
.title-line {
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 2px solid #4d64e4;
  margin: 0 500px;
}

.img1 {
  /* background: radial-gradient(57.03% 57.03% at 50% 50%, #3C51A0 0%, #223479 100%); */
  height: 291px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgItem1 {
  color: #333333;
  font-family: "Thonburi";
  font-weight: 700;
  font-size: 34px;
  text-align: center;
}
</style>
