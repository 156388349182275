<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-06-13 16:04:03
 * @LastEditTime: 2024-09-14 11:22:36
 * @LastEditors: xiaoq
-->
<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <ul class="skip">
          <li class="skip_1" @click="gotoPath('/')"></li>
          <li class="skip_2" @click="gotoPath('/about')"></li>
          <li class="skip_3" @click="gotoPath('/question')"></li>
          <li class="skip_4">
            <a
              class="procy"
              href="https://ace.mflexiblefinancesolutions.com/M05fn6F/H8e5mXp3a1a.html"
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/mackale1/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      altText: "图片",
    };
  },
  methods: {
    gotoPath(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  margin-top: -2px;
}

.img1Box {
  position: relative;
}
.skip {
  /* border: 1px solid red; */
  position: absolute;
  left: 13.5%;
  top: 62.5%;
  width: 1022px;
  height: 35px;
  display: flex;
  gap: 153px;
}
.skip li {
  /* border: 1px solid pink; */
  /* border-radius: 10px; */
  cursor: pointer;
}
.skip_1 {
  width: 90px;
}
.skip_2 {
  width: 131px;
}
.skip_3 {
  width: 143px;
}
.skip_4 {
  width: 200px;
}
.procy {
  display: block;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}
</style>
